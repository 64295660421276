import * as Sentry from '@sentry/nextjs';
import NextErrorComponent, { ErrorProps } from 'next/error';

const Error = ({
  statusCode,
  hasGetInitialPropsRun,
  err,
}: ErrorProps & { hasGetInitialPropsRun: boolean; err: any }) => {
  if (!hasGetInitialPropsRun && err) {
    Sentry.captureException(err);
  }

  return <NextErrorComponent statusCode={statusCode} />;
};

export default Error;
